export default [
    {
        path: "index/lists",
        name: "lists",
        component: () => import('v/qrCode/Index/Lists'),
        meta: {
            title: "考试管理",
            is_qrCode: true,
            authorization:true
        }
    },
];
