import {DEVELOPMENT_SERVER_URL, SERVER_URL} from '../config/server'
import axios from 'axios'
import {tansParams} from "@/store/ruoyi";
import router from '@/router'
import store from '@/store'
import {Message} from "element-ui";
const qs = require('qs')
const instance = axios.create({
  baseURL: SERVER_URL,
  timeout: 60000,
  method: 'post',
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded'
  }
})

instance.interceptors.request.use(
  config => {
    if (config.method === 'post' && !config.notQs) {
      config.data = qs.stringify(config.data)
    }
    // 调用接口请求添加token认证信息
    let token = localStorage.getItem('token')
    if (token) config.headers.authorization = token
    return config
  },
  error => {
    return Promise.reject(error)
  }
)
instance.interceptors.response.use(
  response => {
    return response.data
  },
  error => {
    return Promise.reject(error)
  }
)

export default instance

export const sendRequest = (url, data = {}, config = {}) => {
  return new Promise((resolve, reject) => {
    instance({
      url,
      data,
      ...config
    })
      .then(res => {
        if (res.status === 1) {
          resolve(res.data)
        } else {
          reject(res)
        }
      })
      .catch(err => {
        reject()
      })
  })
}


// 通用下载方法
export function download(url, params, filename, tokenName) {
    const token = localStorage.getItem(tokenName);
    return instance
        .post(url, params, {
            transformRequest: [
                params => {
                    return tansParams(params);
                }
            ],
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                "Authorization": "Bearer " + token

            },
            responseType: "blob"
        })
        .then(data => {
            const content = data;
            console.log(content)
            const blob = new Blob([content]);
            if ("download" in document.createElement("a")) {
                const elink = document.createElement("a");
                elink.download = filename;
                elink.style.display = "none";
                elink.href = URL.createObjectURL(blob);
                document.body.appendChild(elink);
                elink.click();
                URL.revokeObjectURL(elink.href);
                document.body.removeChild(elink);
            } else {
                navigator.msSaveBlob(blob, filename);
            }
        })
        .catch(r => {
            console.error(r);
        });
}
