
// export const SERVER_URL = "https://alivc.thussat.net/"; // 正式环境
// export const DEVELOPMENT_SERVER_URL = "https://alivc.thussat.net/"; //开发环境
// export const SERVER_URL = "http://192.168.1.102:2000/"; // 正式环境
// export const DEVELOPMENT_SERVER_URL = "http://192.168.1.102:2000/"; //开发环境

// export const SERVER_URL = "http://192.168.0.190:2000/"; // 正式环境
// export const DEVELOPMENT_SERVER_URL = "http://192.168.0.190:2000/"; //开发环境

// export const SERVER_URL = "http://alivc-test.thussat.net/"; // 正式环境
// export const DEVELOPMENT_SERVER_URL = "http://alivc-test.thussat.net/"; //开发环境

export const SERVER_URL = "https://alivc-test421.thussat.net/"; // 正式环境
export const DEVELOPMENT_SERVER_URL = "https://alivc-test421.thussat.net/"; //开发环境
