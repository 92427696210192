import Vue from 'vue'
const routerMixin = {
  name: 'router-mixin',
  methods: {
    jump(path, query) {
      this.$router.push({
        path,
        query
      })
    },
    back() {
      this.$router.back()
    }
  }
}
Vue.mixin(routerMixin)
