import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "@/store";
// import adminHome from "./admin/home";
// import adminLogin from "./admin/login";
// import adminGroup from "./admin/group";
// import adminExamination from "./admin/examination";
// import adminExaminee from "./admin/examinee";
// import adminStaff from "./admin/staff";
// import adminExamVideo from "./admin/examvideo";
// import adminVideoRecord from "./admin/videorecord"
import indexLogin from "./index/login";
import indexExamination from "./index/examination";

import qrLogin from "./qrCode/login";
import indexCode from "./qrCode/indexCode";

Vue.use(VueRouter)

const routes = [
    {
        path: "/",
        name: "index",
        redirect: "/examination/VideoList",
        component: () =>
            import(/* webpackChunkName: "admin-index" */ "v/index/Index.vue"),
        children: [
            ...indexExamination
        ]
    },
    {
        path: "/login",
        meta: {
            title: "登录",
        },
        component: () =>
            import("v/index/login/Index.vue"),
        children: indexLogin,
    },
    // {
    //     path: "/admin/",
    //     name: "admin",
    //     redirect: "/admin/login",
    //     component: () =>
    //         import("v/admin/login/Index.vue"),
    //     children: adminLogin,
    // },
    // {
    //     path: "/admin",
    //     name: "admin-index",
    //     redirect: "/admin/home",
    //     component: () =>
    //         import(/* webpackChunkName: "admin-index" */ "v/admin/Index.vue"),
    //     children: [
    //         ...adminHome,
    //         ...adminGroup,
    //         ...adminExamination,
    //         ...adminStaff,
    //         ...adminExaminee,
    //         ...adminExamVideo,
    //         ...adminVideoRecord
    //     ]
    // },
    {
        path: "/qrcode",
        name: "qrCode",
        redirect: "/qrcode/login",
        component: () =>
            import("v/qrCode/login/Index.vue"),
        children: qrLogin,
    },
    {
        path: "/qrcode/",
        name: "qrCode-index",
        redirect: "/qrcode/index/lists",
        component: () =>
            import(/* webpackChunkName: "qrCode-index" */ "v/qrCode/Index.vue"),
        children: [
            ...indexCode,
        ]
    },
]

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    // 记录上一页
    if (from) {
        store.commit("setPrePage", from.path);
    }
    // 1.修改页面标题
    document.title = to.meta.title;
    console.log(to.meta)
    // 判断是否是后台系统
    let tokenKey;
    let toLogin;
    let toPage;
    // if (to.meta.is_admin) {
    //     tokenKey = 'admin-token';
    //     toLogin = '/admin/login';
    //     toPage = '/admin/home';
    // } else 
    if (to.meta.is_qrCode) {
        tokenKey = 'qrCode-token';
        toLogin = '/qrcode/login';
        toPage = '/qrcode/index/lists';
    } else {
        tokenKey = 'index-token';
        toLogin = '/login';
        toPage = '/examination/VideoList';
    }
    let token = localStorage.getItem(tokenKey);
    if (to.meta.authorization && to.meta.authorization === true) {
        store.commit("setPrePage", to.path);
        // 2.1.获取登录信息
        if (token == null) {
            // 2.1.1.未登录，跳转到登录页面
            router.push({
                path: toLogin
            })
        } else {
            next();
        }
    } else {
        if (token) {
            router.push({
                path: toPage
            })
        } else {
            next();
        }
    }


});

export default router;
