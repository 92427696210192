export default [
  {
    path: "login",
    name: "qrCode-login",
    component: () => import(/* webpackChunkName: "login" */ "v/qrCode/login/Login.vue"),
    meta: {
      title: "登录",
      is_qrCode: true
    },
  },
];
